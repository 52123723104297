body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-size: x-large;
  margin: 0px;
  height: 100%;
}
html {
  height: 100%;
  overflow: visible;
  overflow-x: hidden
}
#root {
  height: 100vh;
}

.slick-dots {
  position: relative;
  bottom: -0px;
  display: inline-block !important;
  width: 100%;
  padding: 0;
  margin: auto;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 100px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button:before {
  font-family: slick;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #FFF !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}